<template>
  <!-- 電子 type2 -->
  <div
    :id="'menu-' + data.block"
    class="menu mb-3"
  >
    <!-- header -->
    <div class="h-14 menu__header d-flex align-center px-4">
      <div class="menu__header--title font-weight-bold icon--text">
        {{ data.data.navbar[0].game_type }}
      </div>
    </div>

    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 py-0"
      >
        <swiper-slide
          v-for="game in gameItems"
          :key="game._id"
          class="game__item"
        >
          <div
            class="game__item--card"
          >
            <div
              class="rounded"
              :style="gradientBg"
              :class="game.status==='0'?'maintenanceBox':''"
            >
              <!-- 維修中 父層要加 :class="game.status==='0'?'maintenanceBox':''"-->
              <maintenance v-if="game.status=='0'" />
              <v-img
                :src="baseImgUrl + game.image_h5"
                height="152"
                class="rounded cursor-pointer"
                @click="goSlotPage(game.hyperlink)"
              />
            </div>
            <span class="subtitle-2 rounded-b w-100 game-name text-center white--text">
              {{ game.title }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import hexToRgba from 'hex-to-rgba'
import { goSlotPage } from '../../../../utils/slot'
import maintenance from '@/components/maintenance'
export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 2.1,
      spaceBetween: 8,
      centeredSlidesBounds: true,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'gradientBg', 'theme']),

    ...mapState({
      isLogin: state => state.user.isLogin,
    }),

    gameItems() {
      return this.data.data.navbar[0].items
    },

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },

    vendorBg() {
      return `background:linear-gradient(${hexToRgba(this.primaryColor)}, ${hexToRgba(this.primaryColor, '.3')});`
    },
  },

  methods: {
    ...mapActions(['show_alert']),

    /**
     * 進入遊戲列表頁面
     * @date 2021-07-02
     * @param {string} hyperlink 遊戲代碼
     */
    goSlotPage(hyperlink) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }
      goSlotPage(hyperlink)
    },

    /**
     * 設置 slide index (供顯示)
     * @date 2021-06-18
     * @param {number} index slider 索引
     */
    setSliderIndex(index) {
      this.sliderIndex = index
    },

    /**
     * 上一頁
     * @date 2021-06-18
     */
    slidePrev() {
      this.$refs.gameItem.$swiper.slidePrev()
      this.setSliderIndex(this.$refs.gameItem.$swiper.activeIndex)
    },

    /**
     * 下一頁功能
     * @date 2021-06-18
     */
    slideNext() {
      this.$refs.gameItem.$swiper.slideNext()
      this.setSliderIndex(this.$refs.gameItem.$swiper.activeIndex)
      console.log(this.sliderIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.h-14 {
	height: 48px;
}

.menu__header--title {
	font-size: 18px;
}

.game__item--card{
	position: relative;

	.game-name {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 45px;
		line-height: 45px;
		background-image: linear-gradient(rgba(0, 0, 0, 0), #000);
	}
}

</style>
